import InfoOutlined from '@mui/icons-material/InfoOutlined';
import { Tooltip, Typography, type TypographyTypeMap } from '@mui/material';
import React from 'react';

import { useAppProgress } from '@/core/app-progress/mod';

interface InfoIconProps {
    readonly tooltipText: string | React.ReactNode;
    readonly variant?: TypographyTypeMap['props']['variant'];
    readonly inheritColor?: boolean;
    readonly ml?: number;
}

export const InfoIcon: React.FC<InfoIconProps> = ({ tooltipText, inheritColor, variant = 'body4', ml = 0.3 }) => {
    const { isPrint } = useAppProgress();

    return (
        <Tooltip
            arrow
            placement="top"
            enterTouchDelay={0}
            leaveTouchDelay={3000}
            title={<Typography component="legend">{tooltipText}</Typography>}>
            <InfoOutlined
                sx={{
                    fontSize: theme => (variant === 'inherit' ? variant : theme.typography[variant].fontSize),
                    color: theme => (inheritColor ? 'inherit' : theme.vars.palette.grey[500]),
                    mb: 1,
                    ml,
                    display: isPrint ? 'none' : 'inline-block',
                }}
            />
        </Tooltip>
    );
};
