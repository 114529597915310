import { SvgIcon } from '@mui/material';

import Stamp from '@/assets/icons/co2/hvo100/small.svg?react';
import { useLayout } from '@/components/page/layout/mod';

export const CO2Stamp = () => {
    const { isLargeDesktop, isSmallMobile } = useLayout();

    let size = 60;
    if (!isLargeDesktop && !isSmallMobile) size = 45;

    return (
        <SvgIcon
            inheritViewBox
            component={Stamp}
            sx={{
                position: 'absolute',
                top: -size / 3,
                left: -size / 3,
                width: size,
                height: size,
                zIndex: 2,
                '& circle': {
                    fill(theme) {
                        return theme.vars.palette.eco.main;
                    },
                },
            }}
        />
    );
};
