import { RichTextEditor } from '@/components/elements/rich-text-editor';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { createFileRoute } from '@tanstack/react-router';
import { FC, useState } from 'react';
import { object, string } from 'yup';

export const Dev: FC = () => {
    const [value, setValue] = useState('');
    return (
        <Grid2 container>
            <Grid2 xs={2}>
                <RichTextEditor
                    initialValue={value}
                    onChange={value => {
                        setValue(value);
                        console.log(value);
                    }}
                />
            </Grid2>
            <Grid2 xs={10}>
                <div dangerouslySetInnerHTML={{ __html: value }} />
            </Grid2>
        </Grid2>
    );
};

const SEARCH_PARAMS = object({
    token: string().required(),
});

export const Route = createFileRoute('/dev')({
    component: Dev,
    // validateSearch(search): InferType<typeof SEARCH_PARAMS> {
    //     return SEARCH_PARAMS.cast(search);
    // },
    // onError(error) {
    //     if (error.routerCode === 'VALIDATE_SEARCH') redirect({ to: '/', replace: true, throw: true });
    // },
});
