import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, Link } from '@mui/material';
import { useNavigate } from '@tanstack/react-router';
import React, { useMemo } from 'react';
import { FormProvider, useForm, useFormState } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { useCompanyRegistration } from '../api/company-registration';
import { useCompanyRegistrationTemplate } from '../api/company-registration-template';
import { type ICompanyRegistration } from '../types';
import { getCompanyRegistrationDefaultValues, getCompanyRegistrationSchema } from '../utils';

import { duplicateCustomerEmailValidation } from '@/api/user/endpoints';
import dpaPdf from '@/assets/documents/DSGVO_Auftragsverarbeitung_V.2401.1.pdf?url';
import tosPdf from '@/assets/documents/terms_of_service_busfinder_v1.7.pdf?url';
import { ActionLoadingButton } from '@/components/elements/action/action-loading-button';
import { Heading } from '@/components/elements/heading';
import { CheckField } from '@/components/form/fields/check-field';
import { LocationField } from '@/components/form/fields/location-field';
import { RadioButtonGroup } from '@/components/form/fields/radio-button-group';
import { SelectField } from '@/components/form/fields/select-field';
import { TextField } from '@/components/form/fields/text-field';
import { FormFieldRow } from '@/components/form/layout/form-field-row';
import { FormFieldsContainer } from '@/components/form/layout/form-fields-container';
import { useAsyncCheckDuplicate } from '@/components/form/validations';
import { AuthInputs } from '@/components/form-inputs/auth-inputs';
import { ContactDataInputs } from '@/components/form-inputs/contact-data-inputs';
import { supportedLanguages } from '@/config/localization';
import { useSnack } from '@/core/snack/mod';
import { getAPIOptions, getBdoNationalAssociations, getCompanyPlanOptions, getYesNoOptions } from '@/utils/constants';
import { SanitizedInnerHTML } from '@/utils/SanitizedInnerHTML';

export const PartnerRegistrationForm: React.FC = () => {
    const { t } = useTranslation();
    const snack = useSnack();
    const navigate = useNavigate();
    const { data: companyRegistrationTemplate } = useCompanyRegistrationTemplate();
    const registerCompany = useCompanyRegistration();

    const [isCompanyNameTaken, companyNameDuplicateErrorMessage, checkDuplicateCompanyName] =
        useAsyncCheckDuplicate('company-name');

    const schema = useMemo(
        () => getCompanyRegistrationSchema(companyNameDuplicateErrorMessage, isCompanyNameTaken),
        [companyNameDuplicateErrorMessage, isCompanyNameTaken],
    );

    const formMethods = useForm({
        resolver: yupResolver(schema),
        defaultValues: getCompanyRegistrationDefaultValues(),
        mode: 'onBlur',
        shouldUnregister: true,
    });

    const apiInterest = formMethods.watch('api');
    const bdoMember = formMethods.watch('bdo');

    const { isSubmitting } = useFormState(formMethods);

    const onSubmit = async (values: ICompanyRegistration) => {
        const email = formMethods.getValues('email');
        try {
            await duplicateCustomerEmailValidation(email);
        } catch {
            formMethods.setError('email', {
                type: 'duplicate-email-validation',
                message: t('validations:email.duplicate_email_customer'),
            });
            return;
        }

        try {
            const token = await registerCompany.mutateAsync(values);

            if (token) await navigate({ to: '/partner-registration/confirmation', search: token });
            else snack.push(t('validations:general.error'), 'error');
        } catch {
            snack.push(t('validations:general.error'), 'error');
        }
    };

    return (
        <FormProvider {...formMethods}>
            <Box mb={3}>
                <Heading color="primary" title={t('partner_registration.title')} />
            </Box>
            <form noValidate onSubmit={formMethods.handleSubmit(onSubmit)}>
                {/* Company Data */}
                <FormFieldsContainer containerLabel={t('company.company_data')}>
                    <FormFieldRow>
                        <TextField
                            required
                            handleBlur={() => {
                                void checkDuplicateCompanyName(formMethods.getValues('companyName'), async () => {
                                    return formMethods.trigger('companyName');
                                });
                            }}
                            name="companyName"
                            label={t('company.name')}
                        />

                        <LocationField required fullWidth label={t('company.location')} name="location" />
                    </FormFieldRow>
                    <FormFieldRow>
                        <SelectField
                            required
                            label={t('company.language')}
                            name="language"
                            options={supportedLanguages().map(lng => ({
                                value: lng,
                                label: t(`localization:language.${lng}`),
                            }))}
                        />
                    </FormFieldRow>
                </FormFieldsContainer>

                {/* Invoice Data */}
                <FormFieldsContainer containerLabel={t('company.invoice_data')}>
                    <FormFieldRow>
                        <TextField required name="registeredCompanyName" label={t('company.registered_name')} />

                        <TextField required name="uid" label={t('company.uid')} />
                    </FormFieldRow>

                    {/* Contact Data */}
                    <FormFieldRow>
                        <ContactDataInputs />
                    </FormFieldRow>
                </FormFieldsContainer>

                {/* User Data */}
                <FormFieldRow>
                    <AuthInputs />
                </FormFieldRow>

                {/* Interested in Online Payment */}
                <FormFieldRow>
                    <RadioButtonGroup
                        label={t('company.interested_customer_online_payment')}
                        name="onlinePayment"
                        options={getYesNoOptions()}
                    />
                </FormFieldRow>

                {/* Interested in API */}
                <FormFieldRow>
                    <RadioButtonGroup label={t('company.interested_api')} name="api" options={getYesNoOptions()} />
                </FormFieldRow>

                {apiInterest && (
                    <FormFieldsContainer>
                        {getAPIOptions().map(option => (
                            <Grid key={option.id} item xs={4} sm={2}>
                                <CheckField label={option.label} name={`apis.${option.id}`} />
                            </Grid>
                        ))}

                        <FormFieldRow>
                            <CheckField label={t('company.order_api')} name="orderApi" />
                        </FormFieldRow>
                        <FormFieldRow>
                            <TextField multiline rows={5} name="apiCustomText" label={t('company.api_custom_text')} />
                        </FormFieldRow>
                    </FormFieldsContainer>
                )}

                {/* BDO member */}
                <FormFieldRow>
                    <RadioButtonGroup label={t('company.bod_member')} name="bdo" options={getYesNoOptions()} />
                </FormFieldRow>

                {bdoMember && (
                    <FormFieldsContainer>
                        <FormFieldRow>
                            <TextField name="bdoNumber" label={t('company.bdoNumber')} />

                            <SelectField
                                name="bdoNationalAssociation"
                                label={t('company.bdoNationalAssociation')}
                                options={getBdoNationalAssociations()}
                            />
                        </FormFieldRow>
                    </FormFieldsContainer>
                )}

                {/* Company Plans */}
                <FormFieldRow>
                    <RadioButtonGroup
                        label={
                            <Trans
                                i18nKey="company.company_plan.question"
                                components={{
                                    anchor: (
                                        <Link
                                            target="_blank"
                                            rel="noreferrer"
                                            href="https://www.busfinder.com/fuer-busunternehmen/gebuehren/"
                                        />
                                    ),
                                }}
                            />
                        }
                        name="companyPlan"
                        options={getCompanyPlanOptions()}
                    />
                </FormFieldRow>

                <FormFieldRow insertEmptyColumn>
                    <TextField name="couponCode" label={t('company.coupon_code')} />
                </FormFieldRow>

                {/* Admin Company Registration Template Text */}
                {companyRegistrationTemplate?.text && (
                    <Grid container>
                        <SanitizedInnerHTML html={`<br/>${companyRegistrationTemplate.text}`} />
                    </Grid>
                )}

                {/* AGB */}
                <FormFieldRow>
                    <CheckField
                        label={
                            <Trans
                                i18nKey="company.agb_registration"
                                components={{
                                    anchorTOS: <Link target="_blank" rel="noreferrer" href={tosPdf} />,
                                    anchorDPA: <Link target="_blank" rel="noreferrer" href={dpaPdf} />,
                                }}
                            />
                        }
                        name="agb"
                    />
                </FormFieldRow>

                <FormFieldRow>
                    <ActionLoadingButton loading={isSubmitting} type="submit">
                        {t('company.register')}
                    </ActionLoadingButton>
                </FormFieldRow>
            </form>
        </FormProvider>
    );
};
