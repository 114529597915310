import { Location } from '@/entity/basic/Location';
import { CustomerGroup } from '@/entity/customergroup/CustomerGroup';

export class PreloadedCustomerDetails {
    public email: string;
    public organizationName: string;
    public customerGroup?: CustomerGroup;
    public organizationLocation?: Location;
    public invitedFirstName?: string;
    public invitedLastName?: string;

    constructor(json: Record<string, any>) {
        this.email = json.email;
        this.organizationName = json.organizationName;
        this.customerGroup = new CustomerGroup(json.customerGroup);
        this.organizationLocation = json.organizationLocation
            ? Location.fromJson(json.organizationLocation)
            : undefined;
        this.invitedFirstName = json.invitedFirstName;
        this.invitedLastName = json.invitedLastName;
    }
}
