import { createFileRoute, redirect } from '@tanstack/react-router';

import { queryUser } from '@/api/user/queries';

export const Route = createFileRoute('/account/referral')({
    async beforeLoad({ context }) {
        const user = await queryUser(context.queryClient);
        if (!user?.isCustomer() || !user.isSchoolOrBusinessOrAssociation())
            redirect({ to: '/', replace: true, throw: true });
    },
});

export { Route as AccountReferralRoute };
