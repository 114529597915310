import {
    experimental_extendTheme as extendTheme,
    type Breakpoint,
    type ColorSystem,
    type ColorSystemOptions,
    type Components,
    type CssVarsTheme,
    type CssVarsThemeOptions,
    type PaletteMode,
    type SupportedColorScheme,
    type Theme,
    type TypographyVariants,
} from '@mui/material';
import type {} from '@mui/material/themeCssVarsAugmentation';
// eslint-disable-next-line no-restricted-imports
import { type MixinsOptions } from '@mui/material/styles/createMixins';
// eslint-disable-next-line no-restricted-imports
import {
    type FontStyleOptions,
    type TypographyOptions,
    type TypographyStyleOptions,
} from '@mui/material/styles/createTypography';
import { createBreakpoints, type Breakpoints, type BreakpointsOptions, type ShapeOptions } from '@mui/system';
import merge from 'lodash/merge';

import { type ThemeOverrides } from '@/core/theme/ThemeOverrides';
import { CLASS_TOUR_HIGHLIGHT } from '@/core/tour/mod';

type ColorScheme = Partial<Record<SupportedColorScheme, ColorSystemOptions>>;

interface ThemeProps {
    breakpointScalingFactor?: number;
    portalContainer?: HTMLElement;
    isPrint?: boolean;
    isDesktop?: boolean;
}

export class AppTheme {
    static COLOR_SCHEMES: ColorScheme = {
        light: {
            palette: {
                contrastThreshold: 3,
                tonalOffset: 0.1,
                common: {
                    black: '#000000',
                    white: '#ffffff',
                },
                primary: {
                    main: '#0d66aa',
                },
                secondary: {
                    main: '#feda13',
                },
                error: {
                    main: '#ff5c62',
                },
                warning: {
                    main: '#cb8f17',
                    // light: '#feda13',
                    contrastText: '#ffffff',
                },
                info: {
                    main: '#0d66aa',
                },
                success: {
                    main: '#08ac4c',
                    contrastText: '#ffffff',
                    // light: '#8cc443',
                },
                eco: {
                    main: '#b7cb4a',
                },
                grey: {
                    '50': '#fafafa',
                    '100': '#f6f6f6',
                    '200': '#ededed',
                    '300': '#e8e8e8',
                    '400': '#cccccc',
                    '500': '#9e9e9e',
                    '600': '#6B6B6B',
                    '700': '#5c5c5c',
                    '800': '#333333',
                    '900': '#2e2e2e',
                    A100: '#d5d5d5',
                    A200: '#BDBDBD',
                    A400: '#303030',
                    A700: '#616161',
                },
                background: {
                    default: '#ffffff',
                    disabled: '#d5d5d5',
                },
                text: {
                    primary: '#6B6B6B',
                    disabled: '#9E9E9E',
                },
                action: {
                    disabledOpacity: 0.5,
                },
                FilledInput: {
                    bg: '#fff',
                    hoverBg: '#fff',
                    disabledBg: 'var(--mui-palette-background-disabled)',
                    border: 'var(--mui-palette-background-disabled)',
                    hoverBorder: 'var(--mui-palette-primary-main)',
                    disabledBorder: 'var(--mui-palette-background-disabled)',
                },
                AppBar: {
                    defaultBg: 'var(--mui-palette-background-default)',
                    defaultColor: 'var(--mui-palette-primary-main)',
                },
                Alert: {
                    successStandardBg: 'color-mix(in srgb, var(--mui-palette-success-light),#fff 90%)',
                    successColor: 'color-mix(in srgb, var(--mui-palette-success-dark),#000 25%)',
                    successIconColor: 'var(--mui-palette-success-light)',

                    infoStandardBg: 'color-mix(in srgb, var(--mui-palette-info-light),#fff 90%)',
                    infoColor: 'color-mix(in srgb, var(--mui-palette-info-dark),#000 25%)',
                    infoIconColor: 'var(--mui-palette-info-light)',

                    warningStandardBg: 'color-mix(in srgb, var(--mui-palette-warning-light),#fff 90%)',
                    warningColor: 'color-mix(in srgb, var(--mui-palette-warning-dark),#000 25%)',
                    warningIconColor: 'var(--mui-palette-warning-light)',

                    errorStandardBg: 'color-mix(in srgb, var(--mui-palette-error-light),#fff 90%)',
                    errorColor: 'color-mix(in srgb, var(--mui-palette-error-dark),#000 25%)',
                    errorIconColor: 'var(--mui-palette-error-light)',
                },
                // TODO: TilePart shadows bacame unused. Remove them at some point.
                TilePart: {
                    attachedTopShadow: '0 4px 8px -3px rgba(0,0,0,.3)',
                    attachedBottomShadow: '0 -4px 8px -3px rgba(0,0,0,.3)',
                },
            },
            opacity: {
                disabled: 0.3,
            },
        },
    };

    static BREAKPOINT_OPTIONS: BreakpointsOptions = {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
            small_mobile: 400,
        },
    };

    static SHAPE_OPTIONS: ShapeOptions = {
        borderRadius: 4,
    };

    static breakPointWidth(key: Breakpoint, min = Number.NEGATIVE_INFINITY, max = Number.POSITIVE_INFINITY): string {
        let value = AppTheme.BREAKPOINT_OPTIONS.values?.[key] ?? 0;
        value = Math.max(value, min);
        value = Math.min(value, max);
        return `${value}px`;
    }

    private readonly themeProps: ThemeProps;

    private readonly overrides: ThemeOverrides | undefined;

    constructor(overrides?: ThemeOverrides, props: ThemeProps = {}) {
        this.overrides = overrides;
        this.themeProps = props;
    }

    public create(_mode: PaletteMode = 'light'): Theme {
        // Initialize the base theme
        let theme = extendTheme({
            shape: merge(AppTheme.SHAPE_OPTIONS, this.overrides?.themeOptions.shape),
            // Merge our color scheme with the overrides. Makes sure that light and dark
            // color variations get recalculated based on contrastThreshold and tonalOffset from the
            // overrides and not mixed with our base palette
            colorSchemes: merge(AppTheme.COLOR_SCHEMES, this.overrides?.themeOptions.colorSchemes),
            breakpoints: AppTheme.BREAKPOINT_OPTIONS,
        });

        // Augment custom color such that it gets light/dark/contrastText variations
        theme = extendTheme(
            {
                colorSchemes: {
                    light: {
                        palette: {
                            eco: theme.colorSchemes.light.palette.augmentColor({
                                color: theme.colorSchemes.light.palette.eco,
                                name: 'eco',
                            }),
                        },
                    },
                },
            },
            theme,
        );

        // Update breakpoints with scaling factor
        theme = extendTheme(
            { breakpoints: this.makeScaledBreakpointsPartial(theme) },
            {
                shape: theme.shape,
                colorSchemes: theme.colorSchemes,
            },
        );

        // Extend theme with responsive typography and mixins
        theme = extendTheme(
            {
                mixins: this.makeMixinsOptions(theme.breakpoints),
                typography: this.makeTypographyOptions(theme.breakpoints),
                components: this.makeComponentOptions(),
                googleMaps: this.makeGoogleMapsOptions(theme.colorSchemes.light),
            },
            {
                shape: theme.shape,
                colorSchemes: theme.colorSchemes,
                breakpoints: theme.breakpoints,
            },
        );

        return theme;
    }

    private makeScaledBreakpointsPartial(options: CssVarsThemeOptions): Breakpoints {
        // Crate the breakpoints based on given config
        const breakpoints: Breakpoints = createBreakpoints(options.breakpoints ?? {});

        const scalingFactor = this.themeProps.breakpointScalingFactor ?? 1;

        // If scalingFactor is equal to 1 we are done.
        if (scalingFactor === 1) return breakpoints;

        // Else scale all breakpoint values
        breakpoints.keys.forEach(key => {
            breakpoints.values[key] /= scalingFactor;
        });

        // Use the modified breakpoints object. Copy over keys and values for a new config
        return createBreakpoints({ keys: [...breakpoints.keys], values: { ...breakpoints.values } });
    }

    private makeResponsiveTypographyVariant(
        breakpoints: Breakpoints,
        unit = 'rem',
    ): (size: number, smf?: number) => TypographyStyleOptions {
        const lineHeightFactor = 1.6;
        const defaultFontSize = 14;

        const fontSize = this.themeProps.isPrint
            ? defaultFontSize
            : (this.overrides?.themeOptions.typography as TypographyVariants)?.fontSize ?? defaultFontSize;

        const fontSizeScale = fontSize / 14;

        return (size, smFactor = 0.88) => ({
            fontSize: `${size * fontSizeScale}${unit}`,
            lineHeight: `${size * lineHeightFactor}${unit}`,
            [breakpoints.down('sm')]: {
                fontSize: `${size * fontSizeScale * smFactor}${unit}`,
                lineHeight: `${size * lineHeightFactor * smFactor}${unit}`,
            },
        });
    }

    private makeTypographyOptions(breakpoints: Breakpoints) {
        const baseFontStyle: FontStyleOptions = {
            fontFamily: 'Red Hat Text Variable, Red Hat Text Variable, Open Sans, Helvetica, Arial, sans-serif',
            fontWeightBold: 700,
            fontWeightMedium: 500,
            fontWeightRegular: 400,
        };

        const overridesFontStyle = (this.overrides?.themeOptions.typography as TypographyOptions) ?? baseFontStyle;

        const { fontFamily, fontWeightBold, fontWeightMedium, fontWeightRegular } = this.themeProps.isPrint
            ? baseFontStyle
            : overridesFontStyle;

        const rtv = this.makeResponsiveTypographyVariant(breakpoints);

        // Apply general settings from the overrides if there are any
        const defaultConfig = {
            fontFamily,
            h3: {
                fontWeight: fontWeightBold,
                fontFamily,
                ...rtv(1.35),
            },
            h3Sub: {
                fontWeight: fontWeightMedium,
                fontFamily,
                ...rtv(1),
            },
            h4: {
                fontWeight: fontWeightBold,
                fontFamily,
                ...rtv(1.25),
            },
            h4Sub: {
                fontWeight: fontWeightMedium,
                fontFamily,
                ...rtv(0.94),
            },
            h5: {
                fontWeight: fontWeightBold,
                fontFamily,
                ...rtv(1.15),
            },
            h5Sub: {
                fontWeight: fontWeightMedium,
                fontFamily,
                ...rtv(0.8),
            },
            h6: {
                fontWeight: fontWeightMedium,
                fontFamily,
                ...rtv(1),
            },
            price: {
                fontWeight: fontWeightMedium,
                fontFamily,
                ...rtv(1.15),
            },
            body1: {
                fontWeight: fontWeightRegular,
                fontFamily,
                ...rtv(1),
            },
            body2: {
                fontWeight: fontWeightRegular,
                fontFamily,
                ...rtv(0.9, 1),
            },
            body3: {
                fontWeight: fontWeightRegular,
                fontFamily,
                ...rtv(0.8),
            },
            body4: {
                fontWeight: fontWeightRegular,
                fontFamily,
                ...rtv(0.7),
            },
            code: {
                fontWeight: fontWeightRegular,
                fontFamily: 'Red Hat Mono Variable, Monospace',
                ...rtv(1),
            },
        };

        // Apply specific typography settings from the overrides if there are any (ex. for only the body3 variant)
        return this.overrides && !this.themeProps.isPrint
            ? (this.overrides.applyTo({ typography: defaultConfig }).typography as TypographyOptions)
            : defaultConfig;
    }

    private makeMixinsOptions(breakpoints: Breakpoints): MixinsOptions {
        return {
            toolbar: {
                minHeight: 56,
                [breakpoints.up('md')]: {
                    minHeight: 48,
                },
            },
        };
    }

    private makeComponentOptions(): Components<Omit<Theme, 'components' | 'palette'> & CssVarsTheme> {
        const portalContainer = this.themeProps.portalContainer;

        const sharedRootOverrides = (theme: Omit<Theme, 'components' | 'palette'> & CssVarsTheme) => ({
            '@page': {
                margin: '2cm',
            },
            // Otherwise the body background is white and it covers up the page numbers in the print header
            '@media print': {
                body: {
                    backgroundColor: 'transparent',
                },
            },
            address: {
                fontStyle: 'inherit',
            },

            [`.${CLASS_TOUR_HIGHLIGHT}`]: {
                boxShadow: `0 0 5px 5px ${theme.vars.palette.primary.light}`,
            },
        });

        return {
            MuiScopedCssBaseline: {
                styleOverrides: {
                    root: ({ theme }) => ({
                        height: '100%',
                        '& .MuiInputBase-root *': {
                            // ScopedCssBaseline sets box-sizing: 'inherit' for child components,
                            // overriding CSS classes generated by MUI.
                            // https://github.com/mui/material-ui/issues/20461
                            boxSizing: 'content-box',
                        },
                        ...sharedRootOverrides(theme),
                    }),
                },
            },
            MuiCssBaseline: {
                styleOverrides: theme => ({
                    ...sharedRootOverrides(theme),
                }),
            },
            MuiAppBar: {
                variants: [
                    {
                        props: { color: 'default' },
                        style: ({ theme }) => ({
                            color: theme.vars.palette.AppBar.defaultColor,
                        }),
                    },
                ],
                styleOverrides: {
                    root: ({ theme }) => ({
                        zIndex: theme.vars.zIndex.appBar,
                    }),
                },
            },
            MuiAlert: {
                styleOverrides: {
                    root: ({ theme }) => ({
                        [theme.breakpoints.down('sm')]: {
                            flexWrap: 'wrap',
                        },
                    }),
                    message: ({ theme }) => ({
                        [theme.breakpoints.down('sm')]: {
                            flex: 1,
                        },
                    }),
                },
            },
            MuiLink: {
                defaultProps: {
                    underline: 'hover',
                },
            },
            MuiButton: {
                defaultProps: { size: this.themeProps.isDesktop ? 'small' : 'medium' },
                styleOverrides: {
                    root: {
                        fontWeight: 700,
                        textTransform: 'none',
                    },
                },
                variants: [
                    {
                        props: {
                            variant: 'cta',
                            // color: 'primary',
                        },
                        style: ({ theme }) => ({
                            '&:after': {
                                content: '"NEU"',
                                display: 'inline-block',
                                marginLeft: '0.2rem',
                                marginBottom: '0.5em',
                                fontSize: '0.9em',
                                verticalAlign: 'super',
                                color: theme.vars.palette.secondary.main, // Access theme color
                            },
                        }),
                    },
                ],
            },
            MuiCard: {
                styleOverrides: {
                    root: ({ theme }) => ({
                        boxShadow: theme.vars.shadows[3],
                    }),
                },
            },
            MuiRating: {
                styleOverrides: {
                    root: {
                        display: 'flex',
                        alignItems: 'center',
                        '& .MuiSvgIcon-root': {
                            fontSize: 'inherit',
                        },
                    },
                    sizeSmall: ({ theme }) => ({
                        fontSize: theme.spacing(1.8),
                    }),
                    icon: ({ theme }) => ({
                        color: theme.vars.palette.primary.main,
                    }),
                },
            },
            MuiTabs: {
                styleOverrides: {
                    root: {
                        '.MuiTab-root': {
                            fontSize: 'inherit',
                        },
                    },
                },
            },
            MuiTooltip: {
                styleOverrides: {
                    tooltip: ({ theme }) => ({
                        maxWidth: 600,
                        padding: theme.spacing(1),
                        fontSize: theme.typography.body3.fontSize,
                    }),
                    popper: ({ theme }) => ({
                        zIndex: theme.zIndex.modal - 1,
                    }),
                },
            },
            MuiFormLabel: {
                styleOverrides: {
                    root: ({ theme }) => ({
                        [theme.breakpoints.down('sm')]: {
                            fontSize: theme.typography.body2.fontSize,
                        },
                    }),
                },
            },
            MuiTextField: {
                defaultProps: {
                    variant: 'filled',
                },
            },
            MuiFormControl: {
                defaultProps: {
                    variant: 'filled',
                },
                styleOverrides: {
                    root: {
                        '&:has(input[type="hidden"])': {
                            position: 'absolute',
                            '.MuiInputBase-root': {
                                borderWidth: 0,
                            },
                        },
                    },
                },
            },
            MuiFilledInput: {
                styleOverrides: {
                    root: ({ theme }) => ({
                        borderWidth: '1px',
                        borderStyle: 'solid',
                        borderColor: theme.vars.palette.FilledInput.border,
                        borderRadius: theme.vars.shape.borderRadius,
                        '&:hover, &.Mui-focused': {
                            borderColor: theme.vars.palette.FilledInput.hoverBorder,
                        },
                        '&.Mui-disabled': {
                            borderColor: theme.vars.palette.FilledInput.disabledBorder,
                        },
                    }),
                },
                defaultProps: {
                    disableUnderline: true,
                },
            },
            MuiUseMediaQuery: {
                defaultProps: {
                    noSsr: true,
                },
            },
            MuiPopover: {
                defaultProps: {
                    container: portalContainer,
                },
            },
            MuiPopper: {
                defaultProps: {
                    container: portalContainer,
                },
            },
            MuiModal: {
                defaultProps: {
                    container: portalContainer,
                },
                styleOverrides: {
                    root: {
                        '& .MuiDialog-paperWidthXs': {
                            maxWidth: AppTheme.breakPointWidth('xs', 444),
                        },
                        '& .MuiDialog-paperWidthSm': {
                            maxWidth: AppTheme.breakPointWidth('sm'),
                        },
                        '& .MuiDialog-paperWidthMd': {
                            maxWidth: AppTheme.breakPointWidth('md'),
                        },
                        '& .MuiDialog-paperWidthLg': {
                            maxWidth: AppTheme.breakPointWidth('lg'),
                        },
                        '& .MuiDialog-paperWidthXl': {
                            maxWidth: AppTheme.breakPointWidth('xl'),
                        },
                    },
                },
            },
            MuiSnackbar: {
                styleOverrides: {
                    anchorOriginTopLeft: ({ theme }) => ({
                        [theme.breakpoints.up('sm')]: {
                            top: 'calc(var(--bf-env-boundary-offset-top, 0px) + 24px)',
                        },
                        top: 'calc(var(--bf-env-boundary-offset-top, 0px) + 12px)',
                    }),
                    anchorOriginTopCenter: ({ theme }) => ({
                        [theme.breakpoints.up('sm')]: {
                            top: 'calc(var(--bf-env-boundary-offset-top, 0px) + 24px)',
                        },
                        top: 'calc(var(--bf-env-boundary-offset-top, 0px) + 12px)',
                    }),
                    anchorOriginTopRight: ({ theme }) => ({
                        [theme.breakpoints.up('sm')]: {
                            top: 'calc(var(--bf-env-boundary-offset-top, 0px) + 24px)',
                        },
                        top: 'calc(var(--bf-env-boundary-offset-top, 0px) + 12px)',
                    }),
                    anchorOriginBottomCenter: ({ theme }) => ({
                        [theme.breakpoints.up('sm')]: {
                            bottom: 'calc(var(--bf-env-boundary-offset-bottom, 0px) + 24px)',
                        },
                        bottom: 'calc(var(--bf-env-boundary-offset-bottom, 0px) + 12px)',
                    }),
                    anchorOriginBottomLeft: ({ theme }) => ({
                        [theme.breakpoints.up('sm')]: {
                            bottom: 'calc(var(--bf-env-boundary-offset-bottom, 0px) + 24px)',
                        },
                        bottom: 'calc(var(--bf-env-boundary-offset-bottom, 0px) + 12px)',
                    }),
                    anchorOriginBottomRight: ({ theme }) => ({
                        [theme.breakpoints.up('sm')]: {
                            bottom: 'calc(var(--bf-env-boundary-offset-bottom, 0px) + 24px)',
                        },
                        bottom: 'calc(var(--bf-env-boundary-offset-bottom, 0px) + 12px)',
                    }),
                },
            },
            MuiDrawer: {
                styleOverrides: {
                    paper: {
                        top: 'var(--bf-env-boundary-offset-top, 0)',
                        bottom: 'var(--bf-env-boundary-offset-bottom, 0)',
                        height: 'unset',
                    },
                },
            },
            MuiDialog: {
                styleOverrides: {
                    root: {
                        top: 'var(--bf-env-boundary-offset-top, 0)',
                        bottom: 'var(--bf-env-boundary-offset-bottom, 0)',
                    },
                },
            },
        };
    }

    private makeGoogleMapsOptions(colorSystem: ColorSystem): google.maps.MapTypeStyle[] {
        // We use the color values directly instead of using CSS Variables.
        // Google maps is not capable of using CSS Variables.
        const { palette } = colorSystem;

        return [
            {
                elementType: 'geometry',
                stylers: [{ color: palette.grey[100] }],
            },
            {
                elementType: 'labels.icon',
                stylers: [{ visibility: 'off' }],
            },
            {
                elementType: 'labels.text.fill',
                stylers: [{ color: palette.grey.A700 }],
            },
            {
                elementType: 'labels.text.stroke',
                stylers: [{ color: palette.grey[100] }],
            },
            {
                featureType: 'administrative.land_parcel',
                elementType: 'labels.text.fill',
                stylers: [{ color: palette.grey.A200 }],
            },
            {
                featureType: 'poi',
                elementType: 'geometry',
                stylers: [{ color: palette.grey[200] }],
            },
            {
                featureType: 'poi',
                elementType: 'labels.text.fill',
                stylers: [{ color: palette.grey[600] }],
            },
            {
                featureType: 'poi.park',
                elementType: 'geometry',
                stylers: [{ color: palette.grey[300] }],
            },
            {
                featureType: 'poi.park',
                elementType: 'labels.text.fill',
                stylers: [{ color: palette.grey[500] }],
            },
            {
                featureType: 'road',
                elementType: 'geometry',
                stylers: [{ color: palette.common.white }],
            },
            {
                featureType: 'road.arterial',
                elementType: 'labels.text.fill',
                stylers: [{ color: palette.grey[600] }],
            },
            {
                featureType: 'road.highway',
                elementType: 'geometry',
                stylers: [{ color: palette.grey.A100 }],
            },
            {
                featureType: 'road.highway',
                elementType: 'labels.text.fill',
                stylers: [{ color: palette.grey.A700 }],
            },
            {
                featureType: 'road.local',
                elementType: 'labels.text.fill',
                stylers: [{ color: palette.grey[500] }],
            },
            {
                featureType: 'transit.line',
                elementType: 'geometry',
                stylers: [{ color: palette.grey[300] }],
            },
            {
                featureType: 'transit.station',
                elementType: 'geometry',
                stylers: [{ color: palette.grey[200] }],
            },
            {
                featureType: 'water',
                elementType: 'geometry',
                stylers: [{ color: palette.grey[400] }],
            },
            {
                featureType: 'water',
                elementType: 'labels.text.fill',
                stylers: [{ color: palette.grey[500] }],
            },
        ];
    }
}
