import {
    CssBaseline,
    Experimental_CssVarsProvider as CssVarsProvider,
    GlobalStyles,
    ScopedCssBaseline,
    styled,
    useMediaQuery,
    type PaletteMode,
} from '@mui/material';
import fontAwesomeStyles from 'font-awesome/css/font-awesome.css?raw';
import React, { useMemo, useState, type FC, type PropsWithChildren } from 'react';
import swiperNavigationStyles from 'swiper/css/navigation?raw';
import swiperPaginationStyles from 'swiper/css/pagination?raw';
import swiperStyles from 'swiper/css?raw';

import { richTextCss } from '@/components/elements/rich-text-editor';
import { useAppCtx } from '@/core/app-ctx/mod';
import { useAppProgress } from '@/core/app-progress/mod';
import { AppTheme } from '@/core/theme/AppTheme';
import { AppThemeContext } from '@/core/theme/mod';
import { useEmbedCtx } from '@/embed/mod';

export const AppThemeProvider: FC<PropsWithChildren> = ({ children }) => {
    const [mode, setMode] = useState<PaletteMode>('light');
    const { themeOverrides } = useAppCtx();
    const { isPrint } = useAppProgress();
    const { isEmbedding, breakpointScalingFactor, portalContainer } = useEmbedCtx();
    const isDesktop = useMediaQuery('(min-width: 900px)');

    const colorMode = useMemo(
        () => ({
            toggleMode() {
                setMode(mode === 'light' ? 'dark' : 'light');
            },
            mode,
        }),
        [mode],
    );

    const theme = useMemo(
        () =>
            new AppTheme(themeOverrides, {
                breakpointScalingFactor,
                portalContainer,
                isPrint,
                isDesktop,
            }).create(mode),
        [themeOverrides, breakpointScalingFactor, portalContainer, isPrint, isDesktop, mode],
    );

    return (
        <AppThemeContext.Provider value={colorMode}>
            <CssVarsProvider theme={theme} colorSchemeSelector={isEmbedding ? ':host' : ':root'}>
                {isEmbedding ? (
                    <>
                        <ExtraStyles />
                        <GlobalStyles styles={themeOverrides.customCSS} />
                        <ScopedCssBaseline>{children}</ScopedCssBaseline>
                    </>
                ) : (
                    <>
                        <ExtraStyles />
                        <GlobalStyles styles={themeOverrides.customCSS} />
                        <CssBaseline />
                        {children}
                    </>
                )}
                <Fixit />
            </CssVarsProvider>
        </AppThemeContext.Provider>
    );
};

const ExtraStyles: React.FC = () => {
    return (
        <>
            <GlobalStyles styles={swiperStyles} />
            <GlobalStyles styles={swiperPaginationStyles} />
            <GlobalStyles styles={swiperNavigationStyles} />
            <GlobalStyles styles={fontAwesomeStyles} />
            <GlobalStyles styles={richTextCss} />
        </>
    );
};

// We need to add at least one element with display fixed to the page.
// This forces the browser to do some extra tracking which in turn
// fixes position sticky elements on mobile not behaving correctly
// when the mobile borwser's ui is retracting on scrolling.
const Fixit = styled('div')({
    display: 'fixed',
});
